import * as React from 'react'

import Layout from '../layouts/MainLayout'

export default () => (
  <Layout>
    <h1>You are here!</h1>
    <h2>But nothing found for you #404</h2>
  </Layout>
)
